.two-tables-row {
  position: relative;
  overflow-x: hidden;
}

.ant-col.articles-list,
.ant-col.selection-list {
  overflow-y: auto;
  height: 100%;
  flex: 1;
  transition: transform 0.5s ease;

  .ant-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-card-head {
      min-height: auto;
    }

    .ant-card-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .table-wrapper {
    flex: 1;
    overflow: auto;
  }
  .ant-table {
    overflow-y: auto;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 0.875rem 0.875rem;
  }

  .ant-table-tbody {
    overflow-y: auto;
  }
  .ant-btn-icon-only {
    width: 2rem;
    height: 2rem;
    padding: 2.4px 0;
    font-size: 1rem;
    border-radius: 2px;
    vertical-align: -1px;
  }
  .ant-card-head-title {
    padding: 0.5rem 0;
  }
  .ant-page-header {
    font-size: 0.875rem;
    padding: 0rem 1rem;
  }

  &.articles-list {
    margin-right: 10px;
    border: 2px solid black;
    border-radius: 5px;
    .ant-tabs-nav {
      margin: 0;

      &::before {
        border: 0px;
      }
    }

    .ant-tabs-nav-wrap {
      flex-direction: row-reverse;
    }

    @media (max-width: 1024px) {
      position: absolute;
      margin-right: 0;
      width: 100%;

      &.active {
        transform: translateX(0);
      }
    }
  }

  &.selection-list {
    margin-left: 10px;
    border: 2px solid black;
    border-radius: 5px;

    .selection-footer {
      text-align: right;
      background-color: #fafafa;
    }

    @media (max-width: 1024px) {
      position: absolute;
      margin-left: 0;
      width: 100%;
      transform: translateX(calc(100% + 24px));

      &.active {
        transform: translateX(0);
      }
    }
  }

  .ant-card-body {
    padding: 0;
  }
}

.ant-btn.table-switch {
  position: absolute;
  height: 50px;
  width: 25px;
  padding: 0;
  top: 50%;
  right: 0;
  transition: right 0.5s ease;

  display: none;

  &.left {
    right: calc(100% - 25px);
  }

  @media (max-width: 1024px) {
    display: block;
  }
}
.smtv-input {
  .ant-form-item-control-input-content {
    display: flex;
    .ant-input-number {
      flex: 1;
    }
  }
}

.ant-input-number.tight {
  width: 100% !important;
}
