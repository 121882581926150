.smtv-modal.readonly{

  .ant-form-item-label {
    font-weight: 600;
  }
  
  input, .ant-select-disabled.ant-select-single .ant-select-selector{
    border : none !important;
    cursor: text;
    color : rgba(0, 0, 0, 0.85);
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }

  .ant-select-arrow {
    display: none;
  }
  
}